define("discourse/plugins/discourse-reactions/discourse/components/discourse-reactions-reaction-post", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax", "discourse/lib/get-url", "discourse/lib/text", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _computed, _service, _ajax, _getUrl, _text, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UserStreamItem
    @item={{hash
      username=@reaction.post_user.username
      name=@reaction.post_user.name
      avatar_template=@reaction.post_user.avatar_template
      created_at=@reaction.created_at
      postUrl=this.postUrl
      category=@reaction.category
      title=@reaction.topic.title
      expandedExcerpt=this.updatedExpandedExcerpt
      excerpt=this.updatedExcerpt
      topic_id=@reaction.topic_id
      post_id=@reaction.post_id
      user_id=@reaction.user_id
    }}
  >
    <:bottom>
      {{#if @reaction.reaction.reaction_users_count}}
        <div class="discourse-reactions-my-reaction">
          <img src={{this.emojiUrl}} class="reaction-emoji" />
          <a
            href={{@reaction.user.userUrl}}
            data-user-card={{@reaction.user.username}}
            class="avatar-link"
          >
            {{avatar
              @reaction.user
              imageSize="tiny"
              extraClasses="actor"
              ignoreTitle="true"
            }}
          </a>
        </div>
      {{/if}}
    </:bottom>
  </UserStreamItem>
  */
  {
    "id": "qSbb+shu",
    "block": "[[[8,[39,0],null,[[\"@item\"],[[28,[37,1],null,[[\"username\",\"name\",\"avatar_template\",\"created_at\",\"postUrl\",\"category\",\"title\",\"expandedExcerpt\",\"excerpt\",\"topic_id\",\"post_id\",\"user_id\"],[[30,1,[\"post_user\",\"username\"]],[30,1,[\"post_user\",\"name\"]],[30,1,[\"post_user\",\"avatar_template\"]],[30,1,[\"created_at\"]],[30,0,[\"postUrl\"]],[30,1,[\"category\"]],[30,1,[\"topic\",\"title\"]],[30,0,[\"updatedExpandedExcerpt\"]],[30,0,[\"updatedExcerpt\"]],[30,1,[\"topic_id\"]],[30,1,[\"post_id\"]],[30,1,[\"user_id\"]]]]]]],[[\"bottom\"],[[[[1,\"\\n\"],[41,[30,1,[\"reaction\",\"reaction_users_count\"]],[[[1,\"      \"],[10,0],[14,0,\"discourse-reactions-my-reaction\"],[12],[1,\"\\n        \"],[10,\"img\"],[15,\"src\",[30,0,[\"emojiUrl\"]]],[14,0,\"reaction-emoji\"],[12],[13],[1,\"\\n        \"],[10,3],[15,6,[30,1,[\"user\",\"userUrl\"]]],[15,\"data-user-card\",[30,1,[\"user\",\"username\"]]],[14,0,\"avatar-link\"],[12],[1,\"\\n          \"],[1,[28,[35,7],[[30,1,[\"user\"]]],[[\"imageSize\",\"extraClasses\",\"ignoreTitle\"],[\"tiny\",\"actor\",\"true\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]]],[\"@reaction\"],false,[\"user-stream-item\",\"hash\",\":bottom\",\"if\",\"div\",\"img\",\"a\",\"avatar\"]]",
    "moduleName": "discourse/plugins/discourse-reactions/discourse/components/discourse-reactions-reaction-post.hbs",
    "isStrictMode": false
  });
  let cachedNames = new Map();
  let pendingSearch = null;
  class DiscourseReactionsReactionPost extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "updatedExcerpt", [_tracking.tracked], function () {
      return this.args.reaction.post.excerpt;
    }))();
    #updatedExcerpt = (() => (dt7948.i(this, "updatedExcerpt"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "updatedExpandedExcerpt", [_tracking.tracked], function () {
      return this.args.reaction.post.expandedExcerpt;
    }))();
    #updatedExpandedExcerpt = (() => (dt7948.i(this, "updatedExpandedExcerpt"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "moderatorAction", [(0, _computed.equal)("args.reaction.post.post_type", "site.post_types.moderator_action")]))();
    #moderatorAction = (() => (dt7948.i(this, "moderatorAction"), void 0))();
    constructor() {
      super(...arguments);
      this.updateMentionedUsernames();
    }
    async updateMentionedUsernames() {
      this.updatedExcerpt = await this.replaceMentionsWithFullNames(this.args.reaction.post.excerpt);
      this.updatedExpandedExcerpt = await this.replaceMentionsWithFullNames(this.args.reaction.post.expandedExcerpt);
    }
    static #_6 = (() => dt7948.n(this.prototype, "updateMentionedUsernames", [_object.action]))();
    async replaceMentionsWithFullNames(text) {
      if (!text) {
        return text;
      }
      const mentionRegex = /@([\p{L}\d._]+)/gu;
      const replacedText = await Promise.all(text.split(mentionRegex).map(async (part, index) => {
        if (index % 2 === 1) {
          const fullName = await this.searchUsername(part);
          return `@${fullName || part}`;
        }
        return part;
      }));
      return replacedText.join("");
    }
    async searchUsername(username) {
      username = username.toLowerCase();
      if (cachedNames.has(username)) {
        return cachedNames.get(username);
      }
      if (pendingSearch?.usernames.size <= 50) {
        pendingSearch.usernames.add(username);
        const results = await pendingSearch.search;
        if (results.searchedUsernames.includes(username)) {
          const fullName = results.data.users?.find(user => user.username.toLowerCase() === username)?.name || results.data.groups?.find(group => group.name.toLowerCase() === username)?.full_name;
          cachedNames.set(username, fullName);
          return fullName;
        }
      }
      return this.deferSearch(username);
    }
    async deferSearch(username) {
      const usernamesList = new Set([username]);
      pendingSearch = {
        search: new Promise(resolve => {
          setTimeout(async () => {
            const searchedUsernames = Array.from(usernamesList);
            pendingSearch = null;
            const data = await (0, _ajax.ajax)("/u/search/users.json", {
              data: {
                usernames: searchedUsernames.join(","),
                include_groups: this.siteSettings.show_fullname_for_groups
              }
            });
            resolve({
              searchedUsernames,
              data
            });
          }, 20);
        }),
        usernames: usernamesList
      };
      return this.searchUsername(username);
    }
    get postUrl() {
      return (0, _getUrl.default)(this.args.reaction.post.url);
    }
    get emojiUrl() {
      const reactionValue = this.args.reaction.reaction.reaction_value;
      return reactionValue ? (0, _text.emojiUrlFor)(reactionValue) : null;
    }
  }
  _exports.default = DiscourseReactionsReactionPost;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DiscourseReactionsReactionPost);
});